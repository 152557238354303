<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$lssjBjglSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;" :dynamic='true' :setSearchWidth="'70%'" :setOperationWidth="'30%'"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :isShowCheck="false" :receiveTreedata="orgsTree" :incomeTreeData="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" @nodeClickOne="nodeClickOne"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" :hasSelection="true" @handleSelectionChange="handleSelectionChange"  @tableColumnClick="tableColumnClick"></basic-table >
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
    </div>


    <!-- 添加弹窗 -->
    <general-form :FormDialog="FormDialog" :FormList="FormList" :title="addTitle" :FormData="addform"  @cancelDialog="cancelDialog" @confirmDialog="confirmDialog"></general-form>
    <el-dialog class="dialog-mini user-dialog" :title="'导出列选择'" :visible.sync="exportHeaderDia"  width="50%" @close="cancelHeaderDialog">
      <el-checkbox-group v-model="checkedHeaderList" @change="handleCheckedHeaderListChange">
        <el-row>
          <el-col  v-for="item in firstHeaderList" :span="8" :key="item.key">
            <el-checkbox :label="item.key" :key="item.key">{{item.description}}</el-checkbox>
          </el-col>
        </el-row>
        
      </el-checkbox-group>
      <div slot="footer">
          <el-button  size="mini" @click="cancelHeaderDialog">取消</el-button>
          <el-button type="primary" size="mini"  @click="handleDownExcel">提交</el-button>
        </div>
    </el-dialog>

  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybqygl',
    components: {
      Sticky,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        checkedHeaderList:[],
        exportHeaderDia:false,
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          TreeIds:[],
          HS_Area:'',
          queryKey: 'SurfaceAddress',
          key:'',
          QueryType:"0",
          abnormalState:'',//异常类型
          processingStatus:'',//处理状态
          timer:[new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),new Date()],
        },
        orgs: [], // 用户可访问到的组织列表
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        FormDialog:false,//添加弹窗
        addform:{
          userNumber:'',
          userName:'',
          installAddress:'',
          surfaceNum:'',
          surfaceName:''
        },
        FormList:{//表格列
          column:[
            {columnKey:'userNumber',columnDescription:'用户编号',columnType:'text',prop:'userNumber',width:'120',placeholder:"用户编号",},
            {columnKey:'userName',columnDescription:'用户名',columnType:'text',prop:'userName',width:'120',placeholder:"用户名",},
            {columnKey:'installAddress',columnDescription:'安装地址',columnType:'text',prop:'installAddress',width:'120',placeholder:"安装地址",},
            {columnKey:'surfaceNum',columnDescription:'表编号',columnType:'text',prop:'surfaceNum',width:'120',placeholder:"表编号",},
            {columnKey:'surfaceName',columnDescription:'表名称',columnType:'text',prop:'surfaceName',width:'120',placeholder:"表名称",},
            {columnKey:'warningType',columnDescription:'告警类型',columnType:'text',prop:'warningType',width:'120',placeholder:"告警类型",},
          ],
          //校验
          rule:{
            userNumber: [{ required: true, message: "用户编号不能为空", trigger: "blur" }, ],
          }
        },
        addTitle:'添加',//添加弹窗标题
        // firstHeaderList:[],// 主列表列定义
        firstHeaderList:[// 主列表列定义
          // {
          //   key :  "userNumber",
          //   comment :  "设备编号",
          //   description :  "设备编号",
          // },
          // {
          //   key :  "alarmType",
          //   comment :  "告警类型",
          //   description :  "告警类型",
          // },
          // {
          //   key :  "handling",
          //   comment :  "处理情况",
          //   description :  "处理情况",
          // },
          // {
          //   key :  "equipmentAlarm",
          //   comment :  "告警内容",
          //   description :  "告警内容",
          // },
          // {
          //   key :  "alarmTime",
          //   comment :  "告警时间",
          //   description :  "告警时间",
          // },
        ],
        tableTotal:0,//明细条数
        chooseList:[],//多选数据
      }
    },
    watch: {

    },
    computed: {
      orgsTree() {
        return this.$store.state.allInstitution;
      },
      json_fields() {
      let obj = {};
      this.firstHeaderList.length > 0 &&
        this.firstHeaderList.forEach((item) => {
          obj[item.description] = item.key;
        });
      return obj;
    },
    },
    filters: {
      
    },
    created() {
      // if(this.$route.query.surfaceNum){//如果是页面跳转
      //   this.listQuery.queryKey = 'SurfaceNum'
      //   this.listQuery.key = this.$route.query.surfaceNum//表编号
      // }
      this.getList()
    },
    mounted() {

      // 
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
        // 计算table高度
      })

      this.$basicFun.dragControllerDiv();//拖拽div方法
    },
    methods: {
         // 选择表头事件
         handleCheckedHeaderListChange(e){
      },
      // 取消选择表头弹窗
      cancelHeaderDialog(){
        this.exportHeaderDia =false
      },
      //多选
      handleSelectionChange(val){
        this.chooseList = val
      },
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        console.log(data,741147)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.HS_Area=data.id
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            this.handleDelete(this.tableChooseRow)
            break
          case 'btnAdd'://添加
            this.FormDialog = true
            break
          case 'btnDeal'://处理告警
            if(this.chooseList.length <= 0){
              this.$message.error('请选择一条数据进行操作！');
              return;
            }
            this.dealWarning()
            break
            case 'btnExport'://导出
            this.checkedHeaderList=[]
            this.exportHeaderDia =true
            break
          default:
            break
        }
      },
      handleDownExcel() {
        if(this.checkedHeaderList.length<=0){
          this.$message.error("请先选择需要导出的字段再进行导出")
          return
        }
        var searchQuery = Object.assign({},this.listQuery)
        if(searchQuery.timer&&searchQuery.timer.length == 2){
          searchQuery.StartTime = this.$basicFun.dataFormat(searchQuery.timer[0],'yyyy-MM-dd hh:mm');
          searchQuery.EndTime = this.$basicFun.dataFormat(searchQuery.timer[1],'yyyy-MM-dd hh:mm');
        }
        searchQuery.HS_Area = ""
        let exportHeaderList={}
        this.checkedHeaderList.forEach((item)=>{
          exportHeaderList[item] = this.firstHeaderList.filter((it)=>{return it.key == item})[0].description
        })
        searchQuery.PropInfos = exportHeaderList
        basicDataApi.bjglExport(searchQuery).then(response => {
          if(response.code == 200){
            var url = process.env.VUE_APP_BASE_IMG_URL + '/'+ response.result
            window.open(url)
            this.cancelHeaderDialog()
            // window.location.href = url
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        })
      },
    
      // 获取数据
      getList() {
        this.loading = true
        var searchQuery = Object.assign({},this.listQuery)

        if(searchQuery.timer&&searchQuery.timer.length == 2){
          searchQuery.StartTime = this.$basicFun.dataFormat(searchQuery.timer[0],'yyyy-MM-dd hh:mm');
          searchQuery.EndTime = this.$basicFun.dataFormat(searchQuery.timer[1],'yyyy-MM-dd hh:mm');
        }
        basicDataApi.sjglBjglSearch(searchQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.listQuery.HS_Area =''
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      handleDelete(row) { // 多行删除
        this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      // add弹窗取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add弹窗提交
      confirmDialog(){
        this.$store.state.tableDataSearch.push(this.addform)
        this.addform={
          userNumber:'',
          userName:'',
          installAddress:'',
          surfaceNum:'',
          surfaceName:''
        }
        this.FormDialog = false
      },
      //告警处理
      dealWarning(){
        var ids = []
        this.chooseList.forEach(i=>{
          ids.push(i.id)
        })
        var data={
          ids:ids,
          processingStatus:1
        }
        basicDataApi.gjjldealWarning(data).then(response => {
          if(response.code == 200){
            this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
      },
      //点击跳转列
      tableColumnClick(row){
        this.$router.push({path: '/hdybybzl/index',query:{ surfaceNum:row.surfaceNum}});
      },
    },
  }

</script>

<style lang="scss" scoped>
  
</style>
